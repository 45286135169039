<template>
  <el-main class="datause-container">
    <div class="inner-container">
      <div class="section-h2">
        <MainContain />
        運動數據平台「基本資料」與「身體數值」規格更新，8/1正式實施
      </div>
      <div class="content-desc">
        公告日期：2024-08-01
      </div>
      <div class="content-section">
        <section class="content-section">
          <div class="content-desc">
            親愛的平台用戶：
          </div>
          <div class="content-desc">
            我們將於2024年8月1日對平台的數據規格進行重要更新。這次更新主要涉及生理數據類型中的身體數值主類型，以及基本資料欄位的調整。
          </div>
        </section>
        <section class="content-section">
          <div class="section-h2">
            更新內容概述
          </div>
          <ol>
            <li><strong>生理數據類型調整</strong>：在生理數據類型中，我們將對身體數值主類型進行調整，移除「身高」和「體重」量測項目。</li>
            <li><strong>基本資料欄位新增</strong>：同時，我們將在基本資料中新增「身高」和「體重」作為選填欄位。</li>
          </ol>
        </section>
        <section class="content-section">
          <div class="section-h3-update">
            更新目的說明
          </div>
          <ul>
            <li>將身高與體重數據轉移至基本資料後，所有的數據欄位上傳均可更快速搭配身高體重進行後續研究。</li>
            <li>這項調整有利於體適能常模相關研究的進行，促進跨部會、跨組織的數據更有效運用。</li>
            <li>將有助於提升平台數據的整體價值，為各類研究和應用提供更好的支持。</li>
          </ul>
        </section>
        <section class="content-section">
          <div class="section-h2">
            基本資料欄位變更
          </div>
          <ul>
            <li>新增：身高- 選填</li>
            <li>新增：體重- 選填</li>
            <li>其他基本資料欄位保持不變</li>
          </ul>
        </section>
        <section class="content-section">
          <div class="section-h2">
            生理數據類型變更
          </div>
          <div class="section-h3-update">
            身體數值主類型變更
          </div>
          <ul>
            <li>移除：身高</li>
            <li>移除：體重</li>
            <li>其他量測項目（如蛋白質重、總熱量消耗等）保持不變</li>
          </ul>
        </section>
        <section class="content-section">
          <div class="section-h2">
            變更對照表
          </div>
          <div class="section-h3-update">
            基本資料變更對照表
          </div>
          <table class="update-table">
            <thead>
              <tr>
                <th>欄位</th>
                <th>舊版本</th>
                <th>新版本</th>
                <th>是否必填</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>數據類型 (main_type)</td>
                <td>不變</td>
                <td>不變</td>
                <td>是</td>
              </tr>
              <tr>
                <td>主類型 (type)</td>
                <td>不變</td>
                <td>不變</td>
                <td>是</td>
              </tr>
              <tr>
                <td>子類型 (subtype)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>紀錄日期時間 (start_date)</td>
                <td>不變</td>
                <td>不變</td>
                <td>是</td>
              </tr>
              <tr>
                <td>時區 (timezone)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>活動名稱 (name)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>出生西元年 (birth_year)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>性別 (gender)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>場域縣市 (city)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>場域類型 (place_type)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>設備類型 (device_type)</td>
                <td>不變</td>
                <td>不變</td>
                <td>否</td>
              </tr>
              <tr>
                <td>使用者ID (user_id)</td>
                <td>不變</td>
                <td>不變</td>
                <td>是</td>
              </tr>
              <tr>
                <td>身高 (height)</td>
                <td>不適用</td>
                <td>新增</td>
                <td>選填</td>
              </tr>
              <tr>
                <td>體重 (weight)</td>
                <td>不適用</td>
                <td>新增</td>
                <td>選填</td>
              </tr>
            </tbody>
          </table>
          <div class="section-h3-update">
            生理數據類型 - 身體數值主類型變更對照表
          </div>
          <table class="update-table">
            <thead>
              <tr>
                <th>序號</th>
                <th>量測項目</th>
                <th>狀態</th>
                <th>是否必填</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>蛋白質重</td>
                <td>不變</td>
                <td>多擇一</td>
              </tr>
              <tr>
                <td>2</td>
                <td>總熱量消耗</td>
                <td>不變</td>
                <td>多擇一</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>17</td>
                <td>體重</td>
                <td>已移除</td>
                <td>不適用</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>34</td>
                <td>身高</td>
                <td>已移除</td>
                <td>不適用</td>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
                <td>...</td>
                <td>...</td>
              </tr>
              <tr>
                <td>37</td>
                <td>平均心率</td>
                <td>不變</td>
                <td>多擇一</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section class="content-section">
          <div class="section-h2">
            重要說明
          </div>
          <ol>
            <li>生理數據類型的數據欄位除了移除「身高」和「體重」外，其他所有量測項目保持不變。</li>
            <li>「身高」和「體重」現在作為基本資料的一部分，您可以在那裡填寫這些信息。</li>
            <li>此變更不會影響已上傳的歷史數據。</li>
            <li>請相應地調整您的數據處理流程，以適應這一新的數據結構。</li>
            <li>注意：「是否必填」欄位中的「多擇一」表示在所有身體數值欄位中，用戶需要至少填寫一個欄位，但不要求填寫所有欄位。</li>
          </ol>
        </section>
        <section class="content-section">
          <div class="section-h2">
            數據規格更新 Q&A
          </div>
          <div class="section-h3-update">
            Q1: 修改後，對現有使用者和系統有什麼影響？
          </div>
          <div class="content-desc">
            A1: 我們已經進行了測試，現有的上傳功能不會受到影響。這次變更不會影響現有的上傳路徑或流程。場域端不需要進行任何設定或調整。所有現有的 API 和介面都將保持相容性。
          </div>
          <div class="section-h3-update">
            Q2: 每次上傳資料時是否都需要包含身高體重？
          </div>
          <div class="content-desc">
            A2: 身高和體重從<strong>生理數據類型</strong>移到<strong>基本資料</strong>後，雖然是選填項，但仍然建議儘量填寫該兩欄，以讓您上傳的數據更具實用性。
          </div>
          <div class="section-h3-update">
            Q3: 不同場域（如醫療院所、社區據點、學校、職場）如何適應這個變更？
          </div>
          <div class="content-desc">
            A3: 平台理解不同場域有不同的設備和需求。對於能夠進行 API 串接的智慧醫療設備，變更不會影響其功能。對於需要手動輸入的場所，新的選填選項可能會簡化流程。我們建議各場域根據自身情況和需求來決定是否收集和上傳身高體重資料。
          </div>
          <div class="section-h3-update">
            Q4: 是否需要修改個資當事人同意書？
          </div>
          <div class="content-desc">
            A4: 目前暫無需更動。為確保合規性與透明度，明確說明數據收集與運用的新政策，原本同意書即有涵蓋蒐集身高、體重等，未來同意書若需調整，得根據您收集的具體情況調整。
          </div>
        </section>
        <section class="content-section">
          <div class="section-h2">
            結語
          </div>
          <div class="content-desc">
            如果您有任何疑問或需要技術支援，請隨時聯繫我們的支援團隊。我們將竭誠為您服務，確保您能順利適應這次更新。
          </div>
          <div class="content-desc">
            感謝您的理解和配合。
          </div>
          <div class="content-desc">
            運動數據公益平台維運團隊 敬啟 2024年7月29日
          </div>
        </section>
      </div>
    </div>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'Updates01BasicInfo',
  components: {
    MainContain
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.datause-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .content-section{
    padding: 0.625rem;
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
    }
  }
  li{
    line-height: 1.5;
  }
  .update-table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .update-table th, .update-table td {
    border: 1px solid black;
    padding: 8px;
  }
  ul {
    padding-left: 20px;
  }
  .section-h3-update{
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.67em;
    position: relative; // for aria
  }
}
</style>
